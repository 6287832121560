<template>
  <loader />
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'Login',
  methods: {
    ...mapActions('app', ['doLogin'])
  },
  async created () {
    this.doLogin({ customPath: '/' })
  }
}
</script>
